<template>
  <div id="videos-page">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table :data="data" ref="table" search stripe pagination max-items="12">

        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center">
            <router-link v-if="$acl.check('admin')" :to="{name: 'vBannerCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
            </router-link>
          </div>
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ videos.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : videos.length }} of {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>

        <template slot="thead">
          <vs-th class="w-12"></vs-th>
          <vs-th>{{ $t('fields.image') }}</vs-th>
          <vs-th>{{ $t('fields.title') }}</vs-th>
          <vs-th>{{ $t('fields.description') }}</vs-th>
          <vs-th>{{ $t('props.createdAt') }}</vs-th>
          <vs-th>{{ $t('fields.isAdd') }}</vs-th>
          <!-- <vs-th>Leaf</vs-th> -->
          <vs-th>{{ $t('fields.actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
            <vs-td>
              <vs-button v-if="tr.video" radius color="primary" type="filled" icon-pack="feather" icon="icon-play" @click="playVideo(tr.uuid, 'play', tr.preview)" />
              <vs-button v-if="tr.image" radius color="success" type="filled" icon-pack="feather" icon="icon-image" @click="playVideo(tr.uuid, 'show', false)" />
            </vs-td>
            <!-- <vs-td :data="data[indextr].items" class="block overflow-hidden w-16 px-0 mx-0">
              <div class="img-container relative">
                <img class="w-full my-auto" v-if="tr.preview" :src="`${$baseUrl}/videobanners/preview/${tr.uuid}.webp`" alt="">
                {{ data[indextr].items }}
              </div>
            </vs-td> -->
            <vs-td>
              <div class="img img-container p-0 overflow-hidden rounded-sm w-16 h-16" role="button">
                <img :src="`${$baseUrl}/videobanners/preview/${tr.uuid}.webp`" class="w-full" alt="">
              </div>
            </vs-td>
            <vs-td :data="data[indextr].title_tm">
              {{ data[indextr][`title_${$i18n.locale}`] }}
            </vs-td>
            <vs-td class="custom-truncate" :data="data[indextr][`description_${$i18n.locale}`]">
              {{ data[indextr].description_tm }}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">
              {{ new Date(data[indextr].createdAt).toLocaleString('ru-RU') }}
            </vs-td>
            <vs-td :data="data[indextr].isAdd">
              <vs-chip :color="data[indextr].isAdd ? 'success' : 'danger'">
                <feather-icon v-if="data[indextr].isAdd" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <router-link :to="{name: 'vBannerShow', params: {id: tr.uuid}}" class="text-passive">
                <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
              </router-link>
              <router-link :to="{name: 'vBannerEdit', params: {id: tr.uuid}}">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-succes stroke-current" class="ml-2" />
              </router-link>
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" />
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </vx-card>
    <vs-popup v-show="popupActive" class="holamundo" title="Hello video" :active.sync="popupActive">
      <div class="w-full mb-base">
        <vx-card class="overflow-hidden">
          <template slot="no-body">
            <video-player v-show="playing" ref="player" class="media-video-player card-video" :options="playerOptions" />
            <img v-show="showing" :src="showing" alt="showing" class="mx-auto">
          </template>
        </vx-card>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default{
  components: { videoPlayer },
  data () {
    return {
      playing: '',
      showing: '',
      itemsPerPage: 10,
      popupActive: false,
      isLoading: true,
      videos: [],
      playerOptions: {
        autoplay: false,
        fluid: true,
        height: '360',
        language: 'en',
        muted: true,
        playbackRates: [0.7, 1, 1.5, 2],
        poster: 'https://goo.gl/xcCsDd',
        sources: [{src: 'http://vjs.zencdn.net/v/oceans.mp4', type: 'video/mp4'}]
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  computed: {
    data () { return this.videos },
    currentPage () { return this.isLoading ? 0 : this.$refs.table.currentx },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.videos.length }
  },
  async created () { await this.fetchData() },
  methods: {
    playVideo (uuid, act) {
      if (act === 'play') {
        // this.showing = ''
        // this.playing = `${this.$baseUrl}/videobanners/video/${uuid}.mp4`
        // this.playerOptions.sources[0].src = this.playing
        // this.popupActive = true
        window.open(`${this.$baseUrl}/videobanners/video/${uuid}.mp4`, '_blank')
      } else {
        // this.playing = ''
        // this.showing = `${this.$baseUrl}/videobanners/image/${uuid}.webp`
        window.open(`${this.$baseUrl}/videobanners/image/${uuid}.webp`, '_blank')
      }
    },
    async fetchData () {
      await this.$http.get('/videobanners').then(response => {
        this.videos = response.data.data
        this.isLoading = false
      }).catch(() => { this.isLoading = false })
    },
    async deleteData (uuid) {
      await this.$http.delete(`/videobanners/delete/${uuid}`)
        .then(() => {
          this.fetchData()
          this.$vs.notify({
            title: this.$t('notify.success'),
            text: `${this.$t('banner')} ${this.$t('notify.sDeleted')}`,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'primary'
          })
        })
        .catch(err => {
          this.$vs.notify({
            title: this.$t('notify.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.custom-truncate {
  padding-top: 3px!important;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 80px;
  width: 600px;
  span {
    display: inline-block;
    overflow: hidden;
    height: 95%;
  }
}
#videos-page {
  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 765px;
    }
  }
  .card-video {
    .video-js {
      height: 760px;
    }
  }
  .img {
    padding: 5px;
    width: 40px;
    height: 40px;
    background-color: lightgray;
    border-radius: 25px;
  }
  .media-video-player {
    .video-js.vjs-fluid {
      padding-top: 90%!important;
    }
  }
  .vs-con-table {
    .img {
      background-color: #cdcdcd;
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--tr {
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>
